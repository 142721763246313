import { ICRUDQ } from '@common-src/model/interface';
import { post } from './request';
import { DeviceDriverEntityModel, DeviceDriverQueryModel } from '@common-src/entity-model/device-driver.entity';

const URL_PATH = `${IOT_BASE_REQUEST_PATH}/driver/config`;

class DeviceDriverService implements ICRUDQ<DeviceDriverEntityModel, DeviceDriverQueryModel> {
    async create(model: DeviceDriverEntityModel): Promise<DeviceDriverEntityModel> {
        const url = `${URL_PATH}/import/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string): Promise<any> {
        throw new Error('Not implement.');
    }

    async update(model: DeviceDriverEntityModel): Promise<any> {
        throw new Error('Not implement.');
    }

    async delete(model: DeviceDriverEntityModel): Promise<any> {
        throw new Error('Not implement.');
    }

    async query(query?: any, page?: number, limit?: number): Promise<any> {
        throw new Error('Not implement.');
    }

    /**
     * 解析驱动zip压缩包
     * @param file
     */
    async parseDriverZip(importModel: any) {
        const url = `${URL_PATH}/parseDriverZip`;
        const params = importModel.toService();
        const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000 });
        return res;
    }
}

export default new DeviceDriverService();
