

















import { Component, Mixins } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import DeviceService from '@common-src/service/device';
import { DeviceDriveImportEntityModel, DeviceDriverEntityModel } from '@common-src/entity-model/device-driver.entity';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';

@Component
export default class ImportDriveComponent extends Mixins(TableDialogFormComponent, FormDialogComponent) {
    dialogOpen(importModel: DeviceDriveImportEntityModel, service: ICRUDQ<any, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.dialogTitle = '驱动导入';
        this.$nextTick(() => {
            if (this.jtlForm) {
                this.jtlForm.initForm(importModel, viewMode);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }

        this.startFullScreenLoading('正在解析，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                DeviceService.parseDriverZip(this.jtlForm.formModel).then(res => {
                    if (!_.isEmpty(res)) {
                        const model = new DeviceDriverEntityModel();
                        model.name = res.name;
                        model.releaseDate = res.releaseDate;
                        model.uploadPath = res.uploadPath;
                        model.version = res.version;
                        model.libFileUrl = res.libFileUrl;
                        model.identifier = res.identifier;
                        model.description = res.description;
                        model.jarName = res.jarName;
                        model.category = res.category;
                        model.manufacturer = res.manufacturer;
                        model.definition = JSON.stringify(res.definition);
                        this.$emit('dialogOk', model);
                        this.dialogClose();
                        resolve(null);
                    } else {
                        this.showMessageError(res?.erMessage || '驱动解析失败');
                        resolve(null);
                    }
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
