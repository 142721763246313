



























































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import DeviceDriverDetailDrawer from './device-driver-detail-drawer.vue';
import { DeviceDriverEntityModel, DeviceDriverQueryModel, DeviceDriveImportEntityModel } from '@common-src/entity-model/device-driver.entity';
import { deviceDriverService } from '@common-src/service3/device-driver';
import DeviceDriverService from '@common-src/service/device-driver';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import ImportDriveComponent from '@common-src/pages/dashboard/device/import-drive-zip-dialog.vue';
import { ViewModeType, PropertyEntityType } from '@common-src/model/enum';
import CommonService from '@common-src/service/common';

@Component({
    components: {
        'device-driver-detail-drawer': DeviceDriverDetailDrawer,
        'import-drive-component': ImportDriveComponent
    }
})
export default class DeviceDriverListComponent extends TableDialogFormComponent<DeviceDriverEntityModel, DeviceDriverQueryModel> {
    DeviceDriverEntityModel = DeviceDriverEntityModel;
    PropertyEntityType = PropertyEntityType;
    categoryData: any = [];
    manufacturerData: any = [];
    created() {
        this.initPropertyData();
        this.initTable({
            service: deviceDriverService,
            queryModel: new DeviceDriverQueryModel(),
            tableColumns: DeviceDriverEntityModel.getTableColumns()
        });
        this.getList();
        // this.initMockData(DeviceDriverEntityModel, 20);
    }

    detailClick(model: DeviceDriverEntityModel) {
        (this.$refs.deviceDriverDetailDrawer as DeviceDriverDetailDrawer).drawerShow(model, this.categoryData, this.manufacturerData);
    }

    importDriver() {
        const importDriveModel = new DeviceDriveImportEntityModel();
        (this.$refs.importDriveComponent as ImportDriveComponent).dialogOpen(importDriveModel, null, ViewModeType.NEW);
    }

    saveDriver(model:any) {
        (this.$refs.driverFormDialog as FormDialogComponent<DeviceDriverEntityModel>)
            .dialogOpen(model, DeviceDriverService, ViewModeType.NEW);
    }

    async initPropertyData() {
        this.categoryData = await CommonService.getPropertyOptions(PropertyEntityType.DEVICE_DRIVER_CATEGORY);
        this.manufacturerData = await CommonService.getPropertyOptions(PropertyEntityType.DEVICE_DRIVER_MANUFACTURER);
    }

    formatPropery(code:string, type:string) {
        let text = '';
        if (type === PropertyEntityType.DEVICE_DRIVER_CATEGORY) {
            text = _.get(_.find(this.categoryData, r => r.code === code), 'name');
        }
        if (type === PropertyEntityType.DEVICE_DRIVER_MANUFACTURER) {
            text = _.get(_.find(this.manufacturerData, r => r.code === code), 'name');
        }
        return text;
    }
}
