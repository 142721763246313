



































































import { Component } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { DeviceDriverConnectConfigModel, DeviceDriverEntityModel, DeviceDriverReadConfigModel } from '@common-src/entity-model/device-driver.entity';
import { ThingsFunctionType, ViewModeType, PropertyEntityType } from '@common-src/model/enum';
import { deviceDriverService } from '@common-src/service3/device-driver';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';

@Component
export default class DeviceDriverDetailDrawer extends DrawerComponent {
    model: DeviceDriverEntityModel = null;
    PropertyEntityType = PropertyEntityType;
    categoryData:any = [];
    manufacturerData:any = [];
    get ReadConfigTableColumns() {
        return DeviceDriverReadConfigModel.getTableColumns();
    }

    get ConnectConfigTableColumns() {
        return DeviceDriverConnectConfigModel.getTableColumns();
    }

    drawerShow(model: DeviceDriverEntityModel, categoryData:any, manufacturerData:any) {
        this.categoryData = categoryData;
        this.manufacturerData = manufacturerData;
        this.drawerOpen(model.id, model.name);
        this.init(model.identifier);
    }

    init(identifier: string) {
        this.startFullScreenLoading();
        deviceDriverService.getDetailByIdentifier(identifier)
            .then(res => {
                this.model = res;
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
    }

    editClick() {
        // (this.$refs.formDialog as FormDialogComponent<DeviceDriverEntityModel>).dialogOpen(this.model, deviceService as any, ViewModeType.UPDATE);
    }

    formatPropery(code:string, type:string) {
        let text = '';
        if (type === PropertyEntityType.DEVICE_DRIVER_CATEGORY) {
            text = _.get(_.find(this.categoryData, r => r.code === code), 'name');
        }
        if (type === PropertyEntityType.DEVICE_DRIVER_MANUFACTURER) {
            text = _.get(_.find(this.manufacturerData, r => r.code === code), 'name');
        }
        return text;
    }
}
