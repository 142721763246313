var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerClose },
    },
    [
      _c(
        "div",
        { staticClass: "device-type-detail-component table-component" },
        [
          _vm.model
            ? _c(
                "div",
                { staticClass: "page-body page-body-bg-white" },
                [
                  _c(
                    "a-descriptions",
                    {
                      staticClass: "tab-margin-16",
                      attrs: { bordered: "", column: 2 },
                    },
                    [
                      !_vm.isEdge
                        ? _c(
                            "div",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_c("span", [_vm._v("基本信息")])]
                          )
                        : _vm._e(),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "驱动名称", span: 1 } },
                        [_vm._v(" " + _vm._s(_vm.model.name) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "驱动标识符", span: 1 } },
                        [_vm._v(" " + _vm._s(_vm.model.identifier) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "分类", span: 1 } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatPropery(
                                  _vm.model.category,
                                  _vm.PropertyEntityType.DEVICE_DRIVER_CATEGORY
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "版本", span: 1 } },
                        [_vm._v(" " + _vm._s(_vm.model.version) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "厂家", span: 2 } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatPropery(
                                  _vm.model.manufacturer,
                                  _vm.PropertyEntityType
                                    .DEVICE_DRIVER_MANUFACTURER
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "驱动包地址", span: 2 } },
                        [_vm._v(" " + _vm._s(_vm.model.libFileUrl) + " ")]
                      ),
                      _c(
                        "a-descriptions-item",
                        { attrs: { label: "描述", span: 2 } },
                        [_vm._v(" " + _vm._s(_vm.model.description) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("div", { staticClass: "ant-descriptions-title" }, [
                    _vm._v("配置信息"),
                  ]),
                  _c(
                    "a-card",
                    { attrs: { title: "连接配置" } },
                    [
                      _c("a-table", {
                        attrs: {
                          columns: _vm.ConnectConfigTableColumns,
                          "data-source": _vm.model.connectionParas,
                          pagination: false,
                          "row-key": "identifier",
                          size: "small",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "a-card",
                    { attrs: { title: "映射配置" } },
                    [
                      _c("a-table", {
                        attrs: {
                          columns: _vm.ReadConfigTableColumns,
                          "data-source": _vm.model.variables,
                          pagination: false,
                          "row-key": "identifier",
                          size: "small",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.init },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }